<template>
  <div class="ma-auto">
    
  </div>
</template>
<script>
  import axios from 'axios'
  import { mapGetters, mapActions } from 'vuex'
  import { getToken, getUserID, get } from '../../../worker/worker-api'
  export default {
    name: 'Callback',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Profil',
    },
    methods: {
      getProfile(Ai_UserID) {
        get(`${process.env.VUE_APP_SMT_API_URL}/user/${Ai_UserID}`, {})
        .then(response => {
          this.setRoles(response.a_Roles);
        })
        .catch(error => {
          console.log('error', error)
        });
      },
      ...mapActions(['login', 'setRoles']),
    },
    async mounted() {
      const s_Url = `${process.env.VUE_APP_OAUTH_AUTH_SERVER}/oauth/token`;
      const o_Params = {
        grant_type: 'authorization_code',
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
        redirect_uri: process.env.VUE_APP_OAUTH_CLIENT_REDIRECT,
        code: this.$route.query.code,
      };
      if(await getToken(s_Url, o_Params)) {
        const i_UserID = await getUserID();
        this.login(i_UserID);
        this.getProfile(i_UserID);
      }
      const s_TargetUrl = localStorage.getItem('targetUrl');
      if(s_TargetUrl === null) this.$router.push('/');
      else this.$router.push(s_TargetUrl);
    },
  }
</script>
